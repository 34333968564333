import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@ottimis/ionic-utils';

const routes: Routes = [
  {
    path: 'pre-login',
    loadChildren: () => import('./pre-login/pre-login.module').then(m => m.PreLoginPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'home/profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfilePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'home/book',
    loadChildren: () => import('./book/book.module').then(m => m.BookPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'home/bookings',
    loadChildren: () => import('./bookings/bookings.module').then(m => m.BookingsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'home/info',
    loadChildren: () => import('./info/info.module').then(m => m.InfoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'home/info/segnala',
    loadChildren: () => import('./segnala/segnala.module').then(m => m.SegnalaPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
