import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { InitConfig, IonicUtilsModule } from '@ottimis/ionic-utils';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

const config: InitConfig = {
  url: environment.serverUrl,
  debug: true,
  restDefParams: []
};

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
        backButtonText: '',
        mode: 'ios'
    }),
    AppRoutingModule,
    IonicUtilsModule.forRoot(config)
  ],
  providers: [
    CookieService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
